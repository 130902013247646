/* Hourlypacks.css */

/* Styling the container */
.hourlypacks-container {
  background-color: #f9f9f9;
}

/* Card styling */
.hourlypack-card {
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.hourlypack-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

/* Image wrapper */
.hourlypack-img-wrapper {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.hourlypack-img {
  object-fit: cover;
  height: 250px;
  width: 100%;
  transition: transform 0.5s ease;
}

.hourlypack-card:hover .hourlypack-img {
  transform: scale(1.1);
}

/* Card Body Styling */
.card-body {
  background-color: #f3f3f3;
}

/* Turf name styling */
.turf-name {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
}

/* Offer price styling */
.offer-price {
  font-size: 1.6rem;
  font-weight: bold;
  background: linear-gradient(45deg, #167c82, #167c82);
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
}

/* Custom button styling */
.custom-button {
  background: linear-gradient(45deg, #0a7e52, #0a7e52, #a6ffcb);
  border: none;
  font-weight: bold;
  padding: 0.6rem 1.5rem;
  transition: background 0.3s ease;
  border-radius: 50px;
}

.custom-button:hover {
  background: linear-gradient(45deg, #12d8fa, #1fa2ff);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive styling */
@media (max-width: 768px) {
  .hourlypack-card {
    margin-bottom: 2rem;
  }

  .hourlypack-img {
    height: 200px;
  }
}
