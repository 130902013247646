.featured-slider-group {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.featured-venues-item {
  flex: 0 0 100%;
  padding: 10px;
}

@media (min-width: 768px) {
  .featured-venues-item {
    flex: 0 0 25%;
  }
}
