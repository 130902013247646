/* General container styling for the form section */
.custom-form-content {
  background: linear-gradient(135deg, #167c82, #49a57f);
  padding: 80px 0;
}

.custom-form-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
  border: 2px solid #167c82;
  position: relative;
}

/* Section heading styling */
.custom-form-heading {
  text-align: center;
  margin-bottom: 50px;
}

.custom-form-heading h2 {
  font-size: 3rem;
  font-weight: 700;
  color: #333;
  position: relative;
  text-transform: uppercase;
}

.custom-form-heading h2::after {
  content: "";
  display: block;
  width: 100px;
  height: 5px;
  background: #167c82;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

/* Form styling */
.custom-form-group {
  margin-bottom: 30px;
}

.custom-form-label {
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  display: block;
}

.custom-form-control {
  border-radius: 10px;
  border: 2px solid #167c82;
  padding: 14px;
  background: #f9f9f9;
  transition: border-color 0.3s, box-shadow 0.3s, background 0.3s;
}

.custom-form-control:focus {
  border-color: #167c82;
  background: #ffffff;
}

.custom-form-control.is-invalid {
  border-color: #167c82;
  background: #f8d7da;
}

.custom-form-feedback {
  font-size: 0.9rem;
  color: #167c82;
}

/* Button styling */
.custom-submit-button {
  background: #167c82;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 14px 28px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-submit-button:hover {
  background: #167c82;
  transform: translateY(-2px);
}

.custom-submit-button:active {
  background: #167c82;
  transform: translateY(0);
}

/* Alert styling */
.custom-alert {
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px;
  font-size: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.custom-alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* File input styling */
.custom-file-input {
  padding: 12px;
  border-radius: 10px;
  border: 2px solid #167c82;
  background: #ffffff;
}

/* Responsive design */
@media (max-width: 768px) {
  .custom-form-container {
    padding: 20px;
  }

  .custom-form-heading h2 {
    font-size: 2.5rem;
  }
}
