.new-eventshome {
  margin-top: 86px;
}

.fixed-card-height-events {
  height: 450px; /* Set your desired fixed height */
}

.book-button-events {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
}
