.custom-carousel {
  width: 100%;
}

.carousel-img {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .carousel-item .row {
    display: flex;
  }

  .carousel-item .col-md-6 {
    width: 50%;
  }

  .carousel-caption {
    text-align: right;
  }
}
