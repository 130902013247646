.admin-body {
  font-family: "Arial", sans-serif;
  background-color: #f4f7f9;
  margin: 0;
  padding: 0;
}

.admin-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.admin-container h2 {
  text-align: center;
  color: #333;
  font-weight: bold;
  margin-bottom: 30px;
}

.admin-user-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.admin-user-table th,
.admin-user-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.admin-user-table th {
  background-color: #228988;
  color: white;
  font-weight: bold;
}

.admin-user-table td {
  color: #555;
}

.admin-user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.admin-user-table tr:hover {
  background-color: #f1f1f1;
}

.admin-verify-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.admin-verify-button:hover {
  background-color: #218838;
}
.admin-delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.admin-delete-button:hover {
  background-color: red;
}

.admin-logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}

.admin-logout-button:hover {
  background-color: #c82333;
}

.admin-download-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.admin-download-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .admin-user-table th,
  .admin-user-table td {
    padding: 8px;
  }

  .admin-logout-button,
  .admin-download-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.admin-search-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.admin-search-input {
  padding: 10px 15px;
  border: 2px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  width: 250px;
  transition: border-color 0.3s ease;
}

.admin-search-input:focus {
  border-color: #167c82;
  outline: none;
}

.admin-search-input {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
