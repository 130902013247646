.new-turflist-container {
  margin-top: 40px;
}

.new-clearfilterbutton {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.card-img,
.card-img-bottom,
.card-img-top {
  height: 300px;
  width: 100%;
}
