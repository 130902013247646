/* General Container */
.venue-form-container {
  width: 80%;
  margin: 200px auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.venue-form-container h2 {
  text-align: center;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form Elements */
.venue-form .form-label {
  font-weight: bold;
  color: #555;
}

.venue-form .form-control {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.venue-form .form-control:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* File Input */
.venue-form .form-control[type="file"] {
  padding: 5px;
}

/* Image Preview */
.venue-form-preview img {
  width: 100px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

/* Text Area */
.venue-form textarea.form-control {
  height: 120px;
  resize: none;
}

/* Submit Button */
.venue-form .btn-primary {
  background-color: #167c82;
  border-color: #167c82;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 20%;
  margin-top: 20px;
}

.venue-form .btn-primary:hover {
  background-color: #167c82;
}

/* Responsive */
@media (max-width: 768px) {
  .venue-form-container {
    width: 100%;
    padding: 10px;
  }

  .venue-form .btn-primary {
    font-size: 14px;
    padding: 8px 16px;
  }
}
