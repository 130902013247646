/* .errormessage {
  height: 40px;
  width: 100;
  background-color: rgb(240, 175, 175);
  padding: 5px 10px;
  border: solid 1px red;
  text-align: center;
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.signup {
  position: absolute;
  width: calc(100% - 200px);
  height: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  padding: 20px 10px;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.register-container {
  display: flex;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.register-card {
  background: #fff;
  padding: 2rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
}

.form-section {
  border-right: 1px solid #dee2e6;
}

.form-section h3 {
  margin-bottom: 1rem;
}

.image-section {
  background-size: cover;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-link {
  color: #fff;
  text-align: center;
}

.login-link h5 {
  margin-bottom: 0.5rem;
}

.login-link a {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .form-section {
    border-right: none;
  }
  .image-section {
    display: none;
  }
  .signupfooter {
    padding: 20px 0px !important;
  }
}

.recaptcha-placeholder {
  background-color: #f2f2f2;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
}

.lawn-logos {
  width: 100px;
  border-radius: 15px;
  height: 60px;
}

.new-signupgoogle-button {
  box-shadow: 200px;
}
