.signin-logo {
  width: 100px !important;
  border-radius: 15px !important;
  height: 60px !important;
}

.signin-image {
  border-radius: 10px;
}

@media (max-width: 770px) {
  .signin-left {
    display: none;
  }
  .signin-image {
    width: 100%;
    border-radius: 100px;
  }
}

.newsignin-bottomtext {
  color: #097e52;
}
