/* General container styling for the form section */
.turfreg-content {
  background: linear-gradient(135deg, #167c82, #3f9e7f);
  padding: 60px 0;
}

.turfreg-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  border: 3px solid #2a9d8f;
  position: relative;
}

/* Section heading styling */
.turfreg-section-heading {
  text-align: center;
  margin-bottom: 40px;
}

.turfreg-section-heading h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  position: relative;
  text-transform: uppercase;
}

.turfreg-section-heading h2 span {
  color: #2a9d8f;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Form styling */
.turfreg-form-group {
  margin-bottom: 25px;
}

.turfreg-form-label {
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  display: block;
}

.turfreg-form-control {
  border-radius: 10px;
  border: 2px solid #2a9d8f;
  padding: 12px;
  background: #f9f9f9;
  transition: border-color 0.3s, box-shadow 0.3s, background 0.3s;
}

.turfreg-form-control:focus {
  border-color: #7b92b2;
  box-shadow: 0 0 0 0.3rem rgba(123, 146, 178, 0.25);
  background: #ffffff;
}

.turfreg-form-control.is-invalid {
  border-color: #3f9e7f;
  background: #f8d7da;
}

.turfreg-form-feedback {
  font-size: 0.9rem;
  color: #3f9e7f;
}

/* Button styling */
.turfreg-submit-button {
  background: #2a9d8f;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 12px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.turfreg-submit-button:hover {
  background: #1d9c74;
  transform: translateY(-2px);
}

.turfreg-submit-button:active {
  background: #168a63;
  transform: translateY(0);
}

/* Alert styling */
.turfreg-alert {
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px;
  font-size: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.turfreg-alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.turfreg-alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Button for adding more fields */
.turfreg-add-field-btn {
  background: #f0f0f0;
  border: none;
  color: #333;
  font-size: 1rem;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.turfreg-add-field-btn:hover {
  background: #e0e0e0;
  color: #000;
}

/* Spacing for better layout */
.turfreg-spacer {
  margin-bottom: 30px;
}
