/* General Container */
.features-container {
  width: 80%;
  margin: 200px auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.features-container h2 {
  text-align: center;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form Elements */
.features-form .form-label {
  font-weight: bold;
  color: #555;
}

.features-form .form-control {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.features-form .form-control:focus {
  border-color: #167c82;
  box-shadow: 0 0 5px rgba(22, 124, 130, 0.5);
}

/* Submit Button */
.features-form .btn-primary {
  background-color: #167c82;
  border-color: #167c82;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 20%;
  margin-top: 20px;
  color: white;
  border: none;
}

.features-form .btn-primary:hover {
  background-color: #135d61;
}

/* Responsive */
@media (max-width: 768px) {
  .features-container {
    width: 100%;
    padding: 10px;
  }

  .features-form .btn-primary {
    font-size: 14px;
    padding: 8px 16px;
  }
}
