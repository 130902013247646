.table td,
.table th {
  word-break: break-word;
}

.table th {
  width: auto;
}

.table td {
  width: auto;
  white-space: normal; /* Ensures text wraps */
}
