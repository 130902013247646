.lawn-logo {
  width: 100px;
  border-radius: 15px;
  height: 60px;
}

.new-header-trans {
  background-color: #167c82 !important;
}
.header {
  position: fixed;
  z-index: 10;
  background-color: #167c82 !important;
}
.fas {
  font-size: 20px !important;
}
.bottom-nav {
  gap: 0;

  justify-content: center;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #167c82;
  border-top: 1px solid #ddd;
  padding: 10px 0;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.bottom-nav button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 24px;
  color: #aaf40d;
  margin: 0 12px;
  cursor: pointer;
}
.undesk {
  display: none;
}

.bottom-nav button:hover {
  color: #007bff;
}

@media (max-width: 1024px) {
  .bottom-nav {
    display: flex;
  }

  .main-menu-wrapper {
    display: none;
  }
  .header {
    position: relative;
    z-index: 10;
    background-color: #167c82 !important;
  }
  .undesk {
    display: inline;
  }
  .loginbutton {
    height: 62px !important;
  }
}
