.list-group-item {
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.bg-success {
  background-color: #28a745 !important;
}

.text-white {
  color: #fff !important;
}

.badge {
  font-size: 0.75rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

@media (max-width: 576px) {
  .list-group-item {
    font-size: 0.8rem;
  }
}

.time-disable {
  cursor: not-allowed !important;
  opacity: 0.7;
}
