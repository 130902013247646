.ourteam-container {
  width: 100%;
}

.ourteam-section {
  padding: 20px;
}

.ourteam-inner-container {
  max-width: 1200px;
  margin: 0 auto;
}

.ourteam-heading {
  text-align: center;
  margin-bottom: 20px;
}

.ourteam-featured-slider-group {
  display: flex;
  flex-wrap: nowrap; /* Prevents wrapping */
  overflow-x: auto; /* Allows horizontal scrolling */
  gap: 10px; /* Optional: adds space between items */
  padding: 10px 0; /* Optional: adds padding to the top and bottom */
}

.ourteam-featured-item {
  flex: 0 0 auto; /* Ensures items do not shrink or grow */
  width: 100%; /* Full width for smaller screens */
  max-width: 300px; /* Optional: sets a max-width for items */
  box-sizing: border-box; /* Includes padding in width calculation */
  padding: 10px;
}

@media (min-width: 768px) {
  .ourteam-featured-item {
    width: 50%; /* 2 items per row on tablets and larger screens */
  }
}

@media (min-width: 992px) {
  .ourteam-featured-item {
    width: 50%;
  }
}

.ourteam-img {
  width: 100%;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourteam-p {
  font-size: 12px;
}
