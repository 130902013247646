.custom-dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px;
}

.form-select {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 150px; /* Ensure enough space for the current page text */
}

.form-select:focus {
  outline: none;
  box-shadow: none;
}

.current-page {
  position: absolute;
  right: 10px;
  font-size: 14px;
  color: #6c757d;
  white-space: nowrap;
}
