.rounded-3 {
  border: none;
  padding: 0;
}
.myprofile-section {
  margin-top: 85px;
}

.myprofile-section {
  background-color: #eee;
}

.avatar-image {
  width: 150px;
}

.text-muted {
  color: #6c757d;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

.msb-1 {
  margin-left: 0.25rem;
}

.icon-warning {
  color: #f39c12;
}

.icon-dark {
  color: #333333;
}

.icon-info {
  color: #55acee;
}

.icon-help {
  color: #ac2bac;
}

.icon-primary {
  color: #3b5998;
}

.font-italic {
  font-style: italic;
}

.text-primary {
  color: #bb2a17;
}

.disable-regi {
  cursor: not-allowed !important;
  opacity: 0.8;
}
